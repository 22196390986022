// Angular
import { Injectable, Provider, signal, WritableSignal } from '@angular/core';

// 3rd Party
import { AuthSession, fetchAuthSession } from '@aws-amplify/auth';
import { Predicate, Query, ReturnOption } from '@syncfusion/ej2-data';
import { ScheduleComponent, ResourcesModel, Timezone, Schedule } from '@syncfusion/ej2-angular-schedule';
import { createElement } from '@syncfusion/ej2-base';

// Internal
import { BACKENDURL } from '@environments/environment';
import { Patient, CaseFile, Appointment, ProcedureCode, ListSchedulerLocation, ListModalityType, ListAppointmentStatus, ListAppointmentType, ListModality } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';
import { UpdatedResourceEvent } from '@models/components/scheduler.model';
import { KeyValuePair } from '@services/globals/globals.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { SignalRService } from '@services/api/signal-r.service';
import { ApiService } from '@services/api/api.service';
import { UserPreferencesService } from '../user/user-preferences.service';
import { SchedulerSignalsService } from './scheduler-signals.service';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {

  constructor(
    private api: ApiService,
    private signalR: SignalRService,
    private toast: ToastMessageService,
    private user: UserPreferencesService,
    private schedulerSignals: SchedulerSignalsService
  ) { }

  calendarSignal: WritableSignal<ScheduleComponent | undefined> = signal(undefined);
  calendarStateSignal: WritableSignal<string> = signal('');
  resourceCollectionsSignal: WritableSignal<ResourcesModel[] | undefined> = signal(undefined);

  get calendar(): ScheduleComponent | undefined {
    return this.calendarSignal();
  }

  get resourceCollections(): ResourcesModel[] | undefined {
    return this.resourceCollectionsSignal();
  }

  get calendarState(): string {
    return this.calendarStateSignal();
  }

  async batchSaveSchedule(args: any) {
    args.cancel = true;

    await fetchAuthSession()
      .then(async (session: AuthSession) => {
        const token = session.tokens?.idToken?.toString() as string;
        const changed = args.changedRecords.map((record: any) => this.mapEventForDB(record));
        const added = args.addedRecords.map((record: any) => this.mapEventForDB(record));
        const deleted = args.deletedRecords.map((record: any) => this.mapEventForDB(record));
        
        let data = {
          action: 'batch',
          Changed: changed,
          Added: added,
          Deleted: deleted,
        };
        console.log(data);
        await this.sendXMLData(token, data);
      })
      .catch((err: Error) => {
        console.error(err);
      });
  }

  // Async function to detect XMLHttpRequest  errors
  async sendXMLData(token: string, data: any): Promise<void> {
    try {
      return await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(xhr.response);
          } else {
            reject(new Error(`HTTP error: ${xhr.status} ${xhr.statusText}`));
          }
        };
        xhr.onerror = () => reject(new Error('Network error'));
        xhr.open('POST', `${BACKENDURL}odata/Appointments/batch`);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        xhr.send(JSON.stringify(data));
        this.toast.showSuccess('Event Updated Successfully.');
      });
    } catch (err) {
      console.error('Error:', err);
      this.toast.showError('Failed to update event: ' + (err as any).toString());
    }
  }

  async addAppointment(appointment: Appointment) {
    const response = await this.api.postOdata(`${APIEndpoints.Appointments}`, appointment);
    console.log('post response', response);
  }

  async editAppointment(appointment: Appointment) {
    const response = await this.api.patchOdata(`${APIEndpoints.Appointments}/${appointment.Id}`, appointment);
    console.log('patch response', response);
  }

  async removeAppointment(appointment: Appointment) {
    const response = await this.api.deleteOdata(`${APIEndpoints.Appointments}/${appointment.Id}`);
    console.log('delete response', response);
  }

  // Hub interactions
  receiveLockedDatesSignal(message: any, comp: ScheduleComponent) {
    let elements: any;
    const messageObj = JSON.parse(message);
    
    if (messageObj.attribute === 'id') {
      elements = document.getElementById(messageObj.value);
    } else if (messageObj.attribute === 'class') {
      elements = document.querySelectorAll(`.${messageObj.value}`);
    } else {
      elements = document.querySelectorAll(`[${messageObj.attribute}="${messageObj.value}"]`);
    }

    if (messageObj.action === 'add') {
      elements.forEach((el: Element) => {
        el.classList.add('editing');
        this.signalR.addLoadingIndicatorToElement(el, messageObj.requestingUser);
      });
    } else if (messageObj.action === 'remove') {
      elements.forEach((el: Element) => {
        el.parentElement?.classList.remove('editing');
        el.parentElement?.classList.remove('inactive-bg');
        el.remove();

        this.schedulerSignals.fetchAppointments();
    
        if (el.parentElement?.tagName === 'TD') {
          setTimeout(() => {
            comp.refreshLayout();
            comp.refreshEvents();
          }, 500);
        }
      });
    }
  }

  // Updates associated color for each modality if there is none
  setModalityColor(modalityText: string, count: number): string {
    let opacity: string;
    let hexColor: string = '';
    let darkMode = this.user.getLocalUserPreference('darkMode') === 'on';

    function calculateOpacityAlpha() {
      const increment = (count - 1) * 20;
      const percent = Math.max(0, 100 - increment);
      const scaled = Math.round((percent / 100) * 255);
      const hex = scaled.toString(16);
      const paddedOpacityHex = hex.length === 1 ? '0' + hex : hex;
  
      return paddedOpacityHex;
    }

    if (modalityText.includes('ECT') || modalityText.includes('XRAY')) {
      opacity = calculateOpacityAlpha();
      hexColor = darkMode ? `#959C37${opacity}` : `#B7BE59${opacity}`;
    } else if (modalityText.includes('US')) {
      opacity = calculateOpacityAlpha();
      hexColor = darkMode ? `#2553B5${opacity}` : `#4775D7${opacity}`;
    } else if (modalityText.includes('CV')) {
      opacity = calculateOpacityAlpha();
      hexColor = darkMode ? `#825EA5${opacity}` : `#A47FC7${opacity}`;
    } else if (modalityText.includes('CT')) {
      opacity = calculateOpacityAlpha();
      hexColor = darkMode ? `#2B756E${opacity}` : `#4D978F${opacity}`;
    } else if (modalityText.includes('CR')) {
      opacity = calculateOpacityAlpha();
      hexColor = darkMode ? `#B87535${opacity}` : `#DA9757${opacity}`;
    } else if (modalityText.includes('MR')) {
      opacity = calculateOpacityAlpha();
      hexColor = darkMode ? `#813656${opacity}` : `#A35878${opacity}`;
    } else if (modalityText.includes('MR')) {
      opacity = calculateOpacityAlpha();
      hexColor = darkMode ? `#813656${opacity}` : `#A35878${opacity}`;
    } else if (modalityText.includes('Other') || modalityText.includes('OTHER')) {
      opacity = calculateOpacityAlpha();
      hexColor = darkMode ? `#666666${opacity}` : `#999999${opacity}`;
    }
    return hexColor;
  }

  // Maps data for Syncfusion Scheduler component
  mapEventForSyncfusion(event: any, timezone: Timezone): object {
    return {
      Id: event.Id,
      Subject: event.ProcedureCode.Description,
      StartTime: event.StartDatetime,
      EndTime: event.EndDatetime,
      RecurrenceRule: event.RecurrenceRule,
      RecurrenceException: event.RecurrenceException,
      ProviderId: event.ProviderId,
      IsBlock: event.Block,
      Description: event.Notes,
      CaseFileId: event.CaseFileId,
      IsCompleted: event.IsCompleted,
      AppointmentType: event.AppointmentType,
      AppointmentTypeId: event.AppointmentTypeId,
      AppointmentStatus: event.AppointmentStatus,
      AppointmentStatusId: event.AppointmentStatusId,
      Location: event.Location,
      LocationId: event.Location.Id,
      ModalityId: event.Modality.ModalityTypeId,
      Modality: event.Modality,
      ProcedureCode: event.ProcedureCode,
      ProcedureCodeId: event.ProcedureCodeId,
      CaseFile: event.CaseFile
    }
  }

  // Maps scheduler data so it corresponds to model stored in DB
  mapEventForDB(data: any, withRemovals?: boolean): Appointment {

    if (withRemovals === true) {
      delete data.Location;
      delete data.Modality;
      delete data.AppointmentType;
      delete data.AppointmentStatus;
    }

    return {
      Id: data.Id ?? 0,
      CaseFileId: data.CaseFileId ?? 1,
      ProviderId: data.ProviderId ?? 1,
      StartDatetime: data.StartTime,
      EndDatetime: data.EndTime,
      RecurrenceRule: data.RecurrenceRule ?? '',
      RecurrenceException: data.RecurrenceException ?? '',
      IsAllDay: data.IsAllDay ?? false,
      ProcedureCodeId: data.ProcedureCodeId ?? 1,
      Title: data.Subject ?? '',
      Notes: data.Description ?? '',
      ManagerOverride: data.ManagerOverride ?? false,
      CancellationReason: data.CancellationReason ?? '',
      Block: data.IsBlock ?? false,
      IsCompleted: data.IsCompleted ?? false,
      AppointmentTypeId: data.AppointmentTypeId ?? 1,
      // AppointmentType: data.AppointmentType ?? {},
      AppointmentStatusId: data.AppointmentStatusId ?? 1,
      // AppointmentStatus: data.AppointmentStatus ?? {},
      ModalityId: data.ModalityId ?? 1,
      // Modality: data.Modality ?? {},
      LocationId: data.LocationId ?? 1,
      // Location: data.Location ?? {},
      // CreatedAt: data.CreatedAt ?? new Date(),
      // CreatedBy: data.CreatedBy ?? 0,
      // UpdatedBy: data.UpdatedBy ?? 0,
      // UpdatedAt: data.UpdatedAt ?? new Date()
    };
  }

  // Adds or removes resource to calendar when selecting locations or modalities
  updateResources(args: UpdatedResourceEvent) {
    
    // Exit if missing dependencies
    if (!this.schedulerSignals.calendar.Component()) return;
  
    // Prepare data and update resources
    const resourceCollection = this.schedulerSignals.calendar.Component()?.getResourceCollections().find((collection: any) => collection.name === args.type);
    const modalities = this.schedulerSignals.data.Modalities();
    const matchingResources = modalities?.filter(modality => modality.ModalityTypeId === args.selection.Id) ?? [];
    if (args.event.checked === true) this.addResource(args, matchingResources);
    else this.removeResource(args, matchingResources, resourceCollection);
  
    // Update signals
    this.schedulerSignals.calendar.Component.set(this.schedulerSignals.calendar.Component());
    this.schedulerSignals.calendar.Resources.set(this.schedulerSignals.calendar.Component()?.getResourceCollections());
  }

  private addResource(args: UpdatedResourceEvent, matchingResources: any[]) {

    if (args.type === 'Modalities') {
      matchingResources?.forEach(resource => {
        if (resource.Id) this.schedulerSignals.calendar.Component()?.addResource(resource, args.type, resource.Id); 
        else console.error('Unable to add resource. No Id found', resource);
      });

    } else {
      if (args.selection.Id) this.schedulerSignals.calendar.Component()?.addResource(args.selection, args.type, args.selection.Id);
      else console.error('Unable to add resource. Missing Id', args.selection);
    }
  }

  // Removes resource from calendar
  private removeResource(args: UpdatedResourceEvent, matchingResources: any[], resourceCollection: any) {

    if (args.type === 'Modalities') {
      if (matchingResources.length > 0) {
        matchingResources.forEach(resource => {
          if (resource.Id) this.schedulerSignals.calendar.Component()?.removeResource(Number(resource.Id), 'Modalities');
          else console.error('Unable to remove resource. No Id found', resource);
        });
      } else {
        console.error('Unable to remove resource. No matching resources found', matchingResources)
      };
      
    } else {
      const resourceData = resourceCollection?.dataSource.find((item: any) => item['Id'] === args.selection.Id);
      if (resourceData && args.selection.Id) this.schedulerSignals.calendar.Component()?.removeResource(args.selection.Id, args.type); 
      else console.error('Unable to remove resource.', resourceData, args.selection);
    }
  }

  // Adds new component to editor window when editing appointnments
  addComponentToEditorWindow(element: Element, component: any, attrName?: string, containerClass?: string) {
    const containerDiv: HTMLDivElement = document.createElement('div');
    const inputElement: HTMLInputElement = document.createElement('input');

    containerDiv.className = containerClass !== undefined ? containerClass : 'component-container';
    inputElement.className = 'e-field';
    inputElement.setAttribute('name', attrName as string);
    // let containerDiv: HTMLElement = createElement('div', { 
    //   className: containerClass !== undefined ? containerClass : 'component-container' 
    // });
    // let inputElement: HTMLInputElement = createElement('input', {
    //   className: 'e-field',
    //   attrs: { name: attrName ?? 'NewAttr' }
    // }) as HTMLInputElement;
    
    containerDiv.appendChild(inputElement);
    component.appendTo(inputElement);
    element.appendChild(containerDiv);
  }

  // Get resource data associated with appointment
  getResourceData(data: KeyValuePair, comp: ScheduleComponent): KeyValuePair {
    const resources: ResourcesModel = comp!.getResourceCollections()[1];
    const resourceData: KeyValuePair = (resources.dataSource as Object[])
      .filter((resource: any) => (resource)['Id'] === data['ModalityId'])[0] as KeyValuePair;
    return resourceData;
  }

  // Get colors associated with appointment's modality
  getEventHeaderStyles(data: KeyValuePair, comp: ScheduleComponent): Object {
    const primaryColor = getComputedStyle(document.body).getPropertyValue('--color-sf-primary').trim().replace(/\s*,\s*/g, ', ');

    if (data['elementType'] === 'cell') {
      return { 'align-items': 'center', 'color': '#919191' };
    } else {
      const resourceData: KeyValuePair | undefined = this.getResourceData(data, comp) ?? undefined; 
      let resourceColor = resourceData?.['Color'] as string ?? `rgba(${primaryColor})`;
      const rgbaColor = resourceColor !== `rgba(${primaryColor})` ? this.hexToRgba(resourceColor, 0.33) : `rgba(${primaryColor})`; // Convert to rgba with 33% opacity

      return { 
        'background': rgbaColor, 
        'border-left': `8px solid ${resourceColor}` // Use rgba for border-left
      };
    }
  }

  // Rempve styles associated with Modality
  removeHeaderStyles(): Object {
    return { 'background': 'rgba(var(--color-sf-primary), 0.08)', 'border-left': 'none' };
  }

  // Remove animated elements
  removeUserEditingElements() {
    let userEditingElements = document.querySelectorAll('.user-editing');

    // Remove animating elements
    if (userEditingElements.length > 0) {
      this.signalR.broadcastHTMLElement(userEditingElements[0], 'class', 'remove');
    }
  }

  fetchInstanceType() {
    const query: string = '?$select=VariableValue&$filter=VariableName eq \'instance_type\'';
    return this.api.fetchRequest(`odata${APIEndpoints.ConfigVariables}${query}`);
  }

  // Helper function to convert hex to rgba
  private hexToRgba(hex: string, alpha: number): string {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse r, g, b values
    let r: number, g: number, b: number;
    if (hex.length === 3) {
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else {
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    }

    // Return the rgba string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
}
