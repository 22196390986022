// Angular
import { Injectable } from '@angular/core';

// 3rd party
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { createElement, Internationalization,  EmitType } from '@syncfusion/ej2-base';

// Internal
import { BACKENDURL } from '@environments/environment';
import { CognitoService } from '@services/auth/cognito.service';
import { Appointment } from '@models/data-contracts';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  constructor(
    private cognito: CognitoService,
    private toaster: ToastMessageService,
    private user: UserPreferencesService
  ) {}

  hubConnection: HubConnection;

  // Open Hub connection
  startConnection = () => {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(BACKENDURL + 'realtimehub')
      .build();

    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch((err: Error) => {
        console.log('Error while starting connection: ' + err);
        this.toaster.showError('Error while starting connection: ' + err.message);
      });
  }

  // Open Hub connection to appointments list
  startAppointmentHubConnection = () => {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(BACKENDURL + 'appointmenthub', { accessTokenFactory: () => this.cognito.getUserIdToken() })
      .build();

    this.hubConnection
      .start()
      .catch((err: Error) => {
        console.log('Error while starting connection: ' + err);
        this.toaster.showError('Error while starting connection: ' + err.message);
      });
  }

  // Receive string from the Hub
  addReceiveMessageListener = (callback: (message: string) => void) => {
    this.hubConnection.on('ReceiveMessage', (message) => callback(message));
  }

  /**
   * Section for Appointment hub methods 
   */

  // Returns true if date is not locked, false if date is locked
  requestDateLockForAppointment = async (selectedAppointment: Appointment | null): Promise<boolean> => {
    return await this.hubConnection.invoke("ReceiveRequestForDateLock", selectedAppointment)
      .then(response => response)
      .catch((err: Error) => {
        console.error(err.toString());
        this.toaster.showError('Error receiving request to lock date: ' + err.message);
      });
  }

  // Returns true if date is not locked, false if date is locked
  checkIfDateIsLockedForAppointment = async (selectedAppointment: Appointment): Promise<boolean> => {
    return this.hubConnection.invoke("CheckIfDateLocked",selectedAppointment)
      .then(response => response)
      .catch((err: Error) => {
        console.error(err.toString())
        this.toaster.showError('Error checking if date is locked: ' + err.message);
      });
  }

  // Returns JSON string of message
  addReceiveJSONMessageListener = (callback: (message: string) => void) => {
    this.hubConnection.on('ReceiveJSONMessage', (message) => {
      callback(message);
    });
  }

  // Forwards JSON string to other users connected to the HUB
  brodcastJSONMessage = async (message: string): Promise<string> => {
    return this.hubConnection.invoke("BroadcastJSONMessage", message)
    .then(response => response)
    .catch((err: Error) => {
      console.error(err.toString());
      this.toaster.showError('Error broadcasting message: ' + err.message);
    });
  }

  // Get cached info of locked dates for Scheduler
  getLockedDates = async (): Promise<{[ConnectionId: string]: Appointment}> => {
    return this.hubConnection.invoke("GetLockedDates")
    .then(response => response)
    .catch((err: Error) => {
      console.error(err.toString());
      this.toaster.showError('Error getting locked dates: ' + err.message);
    });
  }

  // Adds HTML elements that animate to represent a user is editing a specific appointment
  addLoadingIndicatorToElement(element: Element, user: string) {
    let userIcon: HTMLElement = createElement('span', { className: 'e-icons e-user' });
    let userEditing: HTMLElement = createElement('div', { className: 'user-editing' });
    let usernameString: HTMLElement = createElement('span', { innerHTML: user, className: 'username' });
    let typingIndicator: HTMLElement = createElement('div', { className: 'typing-indicator'});

    element?.appendChild(userEditing);
    element.classList.add('inactive-bg');
    userEditing.appendChild(userIcon);
    userEditing.appendChild(usernameString);
    userEditing.appendChild(typingIndicator);
  }

  // Adds message string to specific HTML element
  broadcastMessageOnElement(element: Element, message: string, className: string = 'message') {
    let elementMessage = createElement('div', { className: className, innerHTML: message });
    element.classList.add('hub-message');
    element.appendChild(elementMessage);
  }

  // Send HTML element's attribute and attribute value to all users currently listening to HUB
  async broadcastHTMLElement(args: any, attribute: string, action: string | undefined, appointmentData: Appointment | undefined = undefined) {
    const element = args as HTMLElement;
    const elementAttribute = element.getAttribute(attribute) as string;
    const requestingUser = await this.user.getUsername();
    const returnMsg = JSON.stringify({ requestingUser: requestingUser, attribute: attribute, value: elementAttribute, action: action, data: appointmentData });
    this.brodcastJSONMessage(returnMsg);

    if (appointmentData) {
      this.requestDateLockForAppointment(appointmentData);
    }
  }
}
