<div id="scheduler-calendar">

	@if(schedulerSignals.loading.Locations() && schedulerSignals.loading.Modalities() && schedulerSignals.loading.Appointments()) {

		<loading-overlay [loading]="schedulerSignals.loading.Appointments" />

	} @else {

		@if (schedulerSignals.data.ModalityTypes() && schedulerSignals.data.Locations()) {
			<ejs-schedule #calendar
				[id]="componentContextId ? '_ejs-scheduler-' + componentContextId : '_ejs-scheduler-main'"
				[views]="['Day', 'Week', 'WorkWeek', 'TimelineWeek', 'Month', 'WorkMonth', 'TimelineMonth', 'Agenda']"
				[endHour]="'21:00'"
				[startHour]="'06:00'"
				[currentView]="'Day'"
				[group]="{ resources: ['Locations', 'Modalities'], byGroupID: true, allowGroupEdit: true, enableCompactView: false }"
				[enablePersistence]="true"
				[timezone]="localTimezone"
				[eventSettings]="appointments"
				(dataBound)="onDataBound($event)"
				(created)="onCreated($event)"
				(eventRendered)="onEventRendered($event)"
				(popupOpen)="onPopupOpen($event)"
				(popupClose)="onPopupClose($event)"
				(renderCell)="onRenderCell($event)"
				(actionBegin)="onActionBegin($event)" 
				(actionComplete)="onActionComplete($event)">>
				<e-resources>
					<e-resource 
						field="LocationId" 
						title="Location" 
						name="Locations"
						textField="Description" 
						idField="Id" 
						startHourField="LocationHoursStart" 
						endHourField="LocationHoursEnd"
						[dataSource]="schedulerSignals.data.Locations()"
						[allowMultiple]="false">
					</e-resource>
					<e-resource 
						field="ModalityId" 
						title="Modality" 
						name="Modalities" 
						textField="Description" 
						idField="Id" 
						colorField="Color"
						groupIDField="LocationId"
						[dataSource]="schedulerSignals.data.Modalities()" 
						[allowMultiple]="false">
					</e-resource>
				</e-resources>

				<ng-template #eventSettingsTemplate let-data>
					<div class='event appointment'>

						<div class="appointment-header">
							<label>{{data.Subject}}</label>
						</div>

						@if (data.CaseFile) {

							<div class="appointment-details">

								@if (data.CaseFile.Patient) {
									<div class="detail patient">
										<fa-icon [icon]="faCircleUser" />
										<span>{{ data.CaseFile.Patient.Firstname }} {{ data.CaseFile.Patient.Lastname }}</span>
									</div>
								}
								@if (data.ProcedureCode) {
									<div class="detail procedure">
										<fa-icon [icon]="faNotesMedical" />
										<span>{{ data.ProcedureCode.Description }}</span>
									</div>
								}
								@if (data.CaseFile && data.CaseFile.FileNumber) {
									<div class="detail procedure">
										<fa-icon [icon]="faBriefcase" />
										<span>{{ data.CaseFile.FileNumber }}</span>
									</div>
								}
								@if (data.Modality && data.Modality.Description) {
									<div class="detail modality">
										<fa-icon [icon]="faXRay" />
										<span>{{ data.Modality.Description }}</span>
									</div>
								}

							</div>

						} @else {
							<div class="no-info">
								<span>Missing Case File</span>
							</div>
						}
					</div>
				</ng-template>

				<ng-template #editorHeaderTemplate let-data>
					<div *ngIf="data.Subject; else createNewEvent">
						<div class="e-title-text">{{ data.Subject }}</div>
					</div>
					<ng-template #createNewEvent>
					<div class="e-title-text">Add Appointment</div>
					</ng-template>
				</ng-template>
			</ejs-schedule>
		}

		<ejs-dialog id="provider-map-dialog" #providerMapDialog
			[showCloseIcon]="true"
			[target]="'body'"
			[isModal]="true"
			[closeOnEscape]="true"
			[visible]="providerMapDialogVisibility"
			[buttons]="providerMapDialogButtons"
			header="Bulk Edit"
			(close)="closeDialog()">

			<provider-map #providerMap 
				[windowBtnText]="markerWindowTxt" 
				[hideWindowBtnFn]="true" 
				(providerSelected)="onProviderSelected($event)" />

		</ejs-dialog>
	}

</div>