// Angular
import { Component, Output, EventEmitter, computed } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { CheckBoxModule, ButtonModule, ChangeEventArgs } from '@syncfusion/ej2-angular-buttons';

// Internal
// import { Modality } from '@models/components/scheduler';
import { defaultModalityColors, UpdatedResourceEvent } from '@models/components/scheduler.model';
import { ListModality, ListModalityType } from '@models/data-contracts';
import { LoadingModule } from '@modules/loading.module';
import { SchedulerService } from '@services/scheduler/scheduler.service';
import { SchedulerSignalsService } from '@root/src/app/shared/services/scheduler/scheduler-signals.service';

@Component({
  selector: 'scheduler-modalities',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckBoxModule,
    LoadingModule
  ],
  templateUrl: './scheduler-modalities.component.html',
  styleUrl: './scheduler-modalities.component.scss'
})
export class SchedulerModalitiesComponent {
  @Output() clickedModalityType = new EventEmitter<UpdatedResourceEvent>();

  constructor (
    private scheduler: SchedulerService,
    public schedulerSignals: SchedulerSignalsService
  ) {}

  // Public variables
  modalityColors: { [key: number]: string } = {};
  readonly modalityTypes = computed(() => this.schedulerSignals.data.ModalityTypes());
  readonly modalities = computed(() => this.schedulerSignals.data.Modalities());
  
  ngOnInit() {
    this.schedulerSignals.fetchModalities();
    this.schedulerSignals.fetchModalityTypes().then(() => this.setModalityTypeColors());
  }

  // Generate default colors for modality types
  setModalityTypeColors() {

    this.modalityTypes()?.forEach((modalityType: ListModalityType) => {
      const color = defaultModalityColors[String(modalityType.Description)] || 'gray'; // Default to gray if no match
      this.modalityColors[modalityType.Id as number] = color;
  
      // Update ModalityType and ListModalities signals and colors
      if (this.schedulerSignals.data.ModalityTypes()) {
        const modalityToUpdate = this.schedulerSignals.data.ModalityTypes()?.find(mt => mt.Id === modalityType.Id);
        if (modalityToUpdate) modalityToUpdate.Color = color;
        if (modalityToUpdate && modalityToUpdate.ListModalities) modalityToUpdate.ListModalities.forEach(modality => modality.Color = color );

        const matchedModalities = this.schedulerSignals.data.Modalities()?.filter((m: ListModality) => m.ModalityTypeId === modalityType.Id);
        if (matchedModalities) matchedModalities.forEach((m: ListModality) => m.Color = color);
      }
    });
  }

  // Optionally, you can create a method to get color by Id if needed
  getModalityColorById(id: number): string | undefined {
    return this.modalityColors[id]; // Return the color for the given Id
  }
  
  // Emit UpdatedResourceEvent
  onModalityTypeChange(args: ChangeEventArgs, modalityType: ListModalityType) {
    const selected: UpdatedResourceEvent = { type: 'Modalities', event: args, selection: modalityType };
    this.clickedModalityType.emit(selected);
    this.scheduler.updateResources(selected);
  }
}
