// Angular
import { Component, ViewChild, ViewEncapsulation, WritableSignal, signal, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';

// Models
import { Appointment } from '@models/data-contracts';

// Services
import { SchedulerService } from '@services/scheduler/scheduler.service';
import { SchedulerSignalsService } from '@root/src/app/shared/services/scheduler/scheduler-signals.service';

// Components
import { SchedulerLocationsComponent } from './scheduler-locations/scheduler-locations.component';
import { SchedulerModalitiesComponent } from './scheduler-modalities/scheduler-modalities.component';
import { SchedulerCalendarComponent } from './scheduler-calendar/scheduler-calendar.component';

@Component({
  selector: 'scheduler',
  standalone: true,
  imports: [
    CommonModule,
    AccordionModule,
    SchedulerLocationsComponent,
    SchedulerModalitiesComponent,
    SchedulerCalendarComponent
  ],  
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SchedulerComponent {
  
  constructor(
    private scheduler: SchedulerService,
    public schedulerSignals: SchedulerSignalsService
  ) { }

  @Input() appointments: Appointment[];
  @ViewChild('locations') locations: SchedulerLocationsComponent;
  @ViewChild('modalities') modalities: SchedulerModalitiesComponent;
  @ViewChild('scheduleFilter') scheduleFilter: AccordionModule;
  @ViewChild('schedulerCalendar') schedulerCalendar: SchedulerCalendarComponent;
  instanceType: string;
  schedulerClass: string = 'col-12 col-md-10 calendar-content';

  ngOnInit() {
    this.getInstanceType();
    this.initializeCalendarData();
  }

  async getInstanceType() {
   await this.scheduler.fetchInstanceType().then((type) => {
    this.instanceType = type.value[0].VariableValue;

    if (this.instanceType !== 'Hybrid') {
      this.schedulerClass = 'col-12 calendar-content';
    }
   });
  }
  
  async initializeCalendarData() {
    await Promise.all([
      this.schedulerSignals.fetchLocations(),
      this.schedulerSignals.fetchModalityTypes(),
      this.schedulerSignals.fetchModalities(),
      this.schedulerSignals.fetchAppointments()
    ]);
  }
}

