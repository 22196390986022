// Angular
import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { GoogleMap, MapGeocoder, MapGeocoderResponse, MapInfoWindow, MapMarker, MapMarkerClusterer } from '@angular/google-maps';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

// 3rd Party
import { Predicate, Query, ReturnOption } from '@syncfusion/ej2-data';
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { ListViewComponent, ListViewModule } from '@syncfusion/ej2-angular-lists';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Provider } from '@models/data-contracts';
import { MarkerInfo, ProviderType, PhoneNumber } from '@models/components/provider-map.model';
import { ApiService } from '@services/api/api.service';
import { Spacer } from '@ui/spacer/spacer.component';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

@Component({
  selector: 'provider-map',
  standalone: true,
  imports: [
    CommonModule,
    GoogleMap,
    MapMarker,
    MapInfoWindow,
    MapMarkerClusterer,
    ReactiveFormsModule,
    TextBoxModule,
    NumericTextBoxModule,
    MultiSelectModule, 
    ListViewModule,
    Spacer,
    ButtonModule
  ],
  templateUrl: './provider-map.component.html',
  styleUrl: './provider-map.component.scss'
})

export class ProviderMapComponent {
  
  constructor(
    private api: ApiService, 
    private router: Router, 
    private geocoder: MapGeocoder) { }

  // Map variables
  center: google.maps.LatLngLiteral = { lat: 38.84, lng: -104.82 };
  display: google.maps.LatLngLiteral;
  zoom = 9;

  // Marker variables
  @Input() patientAddress: string = 'No Address';
  @Input() windowBtnText: string = 'Schedule Appointment';
  @Input() windowBtnFn: (params: any) => void = ($event) => { 
    this.router.navigate([`/scheduler`]);
  };
  markers: MarkerInfo[] = [];
  patientAddressLatLong = this.center;
  windowReference: MapInfoWindow[] = [];
  markerReference: MapMarker[] = [];
  phoneNumbers: PhoneNumber[] = [];

  // Cluster variables
  clusterMarkerIds: number[] = []
  clusterMarkerIndex = 0;
  placeHolderMapMarker: MapMarker;
  placeHolderPosition: google.maps.LatLngLiteral = { lat: 38.84, lng: -104.82 };
  isCluster = false;

  // Search variables
  @Input() hideWindowBtnFn: boolean = false;
  @ViewChild('providersListView', { static: false }) providersListView: ListViewComponent;
  fields: Object = { text: 'description', value: 'id' };
  clustererImagePath = '/assets/maps/images/m';
  providerTypes: ProviderType[] = [];
  providersListData: { [key: string]: Object; }[];
  searchFormGroup: FormGroup = new FormGroup({
    client_address: new FormControl<string | null>('Colorado Springs, CO', [FormValidators.required]),
    provider_type: new FormControl<number[] | null>(null, [FormValidators.required]),
    radius: new FormControl<number | null>(5, [FormValidators.required]),
  }, {});
  
 /**
  * Initialization
  */
  ngOnInit(): void {
    this.getProviderTypes();
  }

  /**
   * Map markers and popup info
   */
  private autoLoadMapAndMarkers() {

    if (this.patientAddress === "No Address") {
      // Default search from current device's location
      navigator.geolocation.getCurrentPosition(async (position) => {
        let coder = await firstValueFrom(this.geocoder.geocode({ location: { lat: position.coords.latitude, lng: position.coords.longitude } }));
        this.getAddressFromGeocoder(coder);
      });

    } else {
      // If a value is possed in, use it instead
      firstValueFrom(this.geocoder.geocode({ address: this.patientAddress })).then((coder) => { this.getAddressFromGeocoder(coder); })
    }
  }

  // Used to set map position
  private getAddressFromGeocoder(coder: MapGeocoderResponse) {
    this.patientAddress = coder.results[0].formatted_address
    this.patientAddressLatLong = { lat: coder.results[0].geometry.location.lat(), lng: coder.results[0].geometry.location.lng() }
    this.center = this.patientAddressLatLong
    this.searchFormGroup.patchValue({ client_address: this.patientAddress, radius: 50 })
    this.getMarkers(new Query(), this.searchFormGroup.value.radius);
  }

  private getMarkers(query: Query, radius: number) {
    this.markers = [];
    this.windowReference = [];
    this.markerReference = [];

    this.api.getOdata(APIEndpoints.Providers).executeQuery(this.filterQuery(query, radius)).then((e: ReturnOption) => {
      // For each provider, make a marker
      (e.result as object[]).forEach((provider: any, index) => {
        var distance = this.cosineDistance((provider.Latitude as number), (provider.Longitude as number), this.center.lat, this.center.lng);

        // Filters out null and bad values
        if (provider.Latitude != null && provider.Longitude != null && distance < radius) {
          const colorId = provider.InNetwork ? 1 : 2;
          this.markers.push({
            id: provider.Id,
            name: provider.Name,
            notes: provider.Notes,
            latling: { lat: provider.Latitude, lng: provider.Longitude },
            distance: Math.floor(distance * 100) / 100,
            spanishSpeaking: provider.SpanishSpeaking,
            reimbursement: provider.ReimbursementRate,
            inNetwork: provider.InNetwork,
            providerType: provider.ProviderType,
            color: this.getMarkerColors(distance),
          } as MarkerInfo);
        }
      });

      // Sorts the markers by color and distance
      this.markers = this.markers.sort((a: MarkerInfo, b: MarkerInfo) => {

        if ((a.inNetwork as any) - (b.inNetwork as any) != 0) { return (a.inNetwork as any) - (b.inNetwork as any) }
        else { return a.distance - b.distance; }
      });

      this.providersListData = this.convertMarkersForSyncfusion(this.markers);

      this.getPhoneNumbers();
    });
  }

  // Converts the marker array into a syncfusion friendly format
  private convertMarkersForSyncfusion(markers: MarkerInfo[]): { [key: string]: any; }[] {

    return markers.map((element, index) => {

      return {
        index: index,
        id: element.id,
        name: element.name,
        notes: element.notes,
        latling: { lat: element.latling.lat, lng: element.latling.lng },
        distance: element.distance,
        spanishSpeaking: element.spanishSpeaking,
        reimbursement: element.reimbursement,
        inNetwork: element.inNetwork,
        providerType: element.providerType,
        color: element.color,
      };
    });
  }

  getLabel(input: string, color: string): google.maps.MarkerLabel {
    return {
      text: input,
      fontSize: '11px',
      fontWeight: 'bold',
    };
  }

  getMarkerColors(distance: number) {  
    if (distance <= 1) {
      return 'green';
    } else if (distance > 1 && distance <= 4) {
      return 'blue';
    } else if (distance > 4 && distance <= 6) {
      return 'purple';
    } else if (distance > 6 && distance <= 25) {
      return 'orange';
    } else {
      return 'red';
    }
  }

  // Creates a list of info windows whenever a cluster is clicked and opens up the first info window in the list
  clusterClick(c: any) {
    this.isCluster = true;
    this.clusterMarkerIds = [];
    // We have to use -1 cause we need to display starting by 1 but the logic starts with 0
    // Unfortutely a side effect of parsing the label text but this is the simplest method
    c.getMarkers().forEach((element: any) => {
      this.clusterMarkerIds.push(parseInt(element.label.text) - 1)
    });
    this.placeHolderPosition = c.getCenter()
    this.windowReference[this.clusterMarkerIds[0]].open(this.placeHolderMapMarker)
    this.clusterMarkerIndex = 0;
  }

  // Creates an invisible placeholder marker for clicking on clusters
  setPlaceholderMarker(marker: MapMarker) {
    this.placeHolderMapMarker = marker
  }

  // Gets the next info window in the cluster
  getNextInfoWindow(window: MapInfoWindow) {
    window.close()
    if (this.clusterMarkerIndex >= this.clusterMarkerIds.length - 1) {
      this.clusterMarkerIndex = 0;
    }
    else {
      this.clusterMarkerIndex += 1;
    }
    this.windowReference[this.clusterMarkerIds[this.clusterMarkerIndex]].open(this.placeHolderMapMarker)
  }

  // Gets the previous info window in the cluster
  getPreviousInfoWindow(window: MapInfoWindow) {
    window.close()
    if (this.clusterMarkerIndex <= 0) {
      this.clusterMarkerIndex = this.clusterMarkerIds.length - 1;
    }
    else {
      this.clusterMarkerIndex -= 1;
    }
    this.windowReference[this.clusterMarkerIds[this.clusterMarkerIndex]].open(this.placeHolderMapMarker)
  }

  // Gets a list of phone numbers from the database
  getPhoneNumbers() {
    this.phoneNumbers = [];

    this.api.getOdata(APIEndpoints.Phones).executeQuery(new Query().where('PhoneType', 'equal', 2).where('ObjectType', 'equal', 'Provider')).then((e: ReturnOption) => {
      (e.result as object[]).map((phoneNumber: any) => {
        let num: PhoneNumber = { id: phoneNumber.ObjectId as number, PhoneNumber: phoneNumber.PhoneNumber as string }
        this.phoneNumbers.push(num);
      });
    });
  }

  // Gets a phone number from the map
  getNumber(providerId: number | undefined) {
    let res = this.phoneNumbers.find(x => x.id === providerId);

    if (res === undefined) {
      return "No Number Available"
    }
    else {
      return res.PhoneNumber;
    }
  }

  // Applies a small filter on the query so it doesn't need to get as much from the database
  filterQuery(query: Query, radius: number) {
    let a = (this.center.lat + (radius / 60));
    let b = (this.center.lat - (radius / 60));
    let c = (this.center.lng + (radius / 60));
    let d = (this.center.lng - (radius / 60));
    return query.where('latitude', 'lessthanorequal', a).where('latitude', 'greaterthanorequal', b)
      .where('longitude', 'lessthanorequal', c).where('longitude', 'greaterthanorequal', d)
      .where("Active", 'equal', true);
  }

  // Changes the page when the schedule appointment button is clicked
  changePage() { this.router.navigate([`/scheduler`]); }

  // When a marker is clicked open an info window
  OnMarkerclick(window: MapInfoWindow, marker: MapMarker) {
    this.isCluster = false;
    this.clusterMarkerIds = [];
    window.open(marker);
  }

  // Method for calculating distance in miles from the patient address
  cosineDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
    let R = 3958.8;
    let p1 = lat1 * Math.PI / 180;
    let p2 = lat2 * Math.PI / 180;
    let deltaP = p2 - p1;
    let deltaLon = lon2 - lon1;
    let deltaLambda = (deltaLon * Math.PI) / 180;
    let a = Math.sin(deltaP / 2) * Math.sin(deltaP / 2) + Math.cos(p1) * Math.cos(p2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
    let d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * R;
    return (d);
  }

  /**
   * Search Query fns
   */

  getProviderTypes() {

    this.api.getOdata(APIEndpoints.ProviderTypes).executeQuery(new Query()).then((e: ReturnOption) => {
      (e.result as object[]).forEach((element) => {
        let res = element as any
        let provider: ProviderType = {
          id: res.Id,
          description: res.Description,
        }
        this.providerTypes.push(provider);
      });
    });
  }

  async generateMarkersBasedOnFilter() {
    let coder = await firstValueFrom(this.geocoder.geocode({ address: this.searchFormGroup.value.client_address }))
    this.patientAddress = coder.results[0].formatted_address
    this.patientAddressLatLong = { lat: coder.results[0].geometry.location.lat(), lng: coder.results[0].geometry.location.lng() }
    this.center = { lat: this.patientAddressLatLong.lat, lng: this.patientAddressLatLong.lng };
    this.getMarkers(this.addProviderTypeOnSearchQuery(this.searchFormGroup.value.provider_type), this.searchFormGroup.value.radius);
  }

  private addProviderTypeOnSearchQuery(ids: number[]): Query {

    if (ids.length >= 1) {
      let pred: Predicate = new Predicate('ProviderType', 'equal', -1);

      ids.forEach((id) => {
        pred = pred.or(new Predicate('ProviderType', 'equal', id));
      });

      return new Query().where(pred);

    } else {
      return new Query();
    }
  }

  /**
   * Sidebar
   */
  @Output() providerSelected = new EventEmitter<any>();
  addReferenceToWindowMarkerPair(window: MapInfoWindow, marker: MapMarker) {
    this.windowReference.push(window)
    this.markerReference.push(marker)
  }

  recenterMap(index: any) {
    this.zoom = 18
    this.center = { lat: this.markers[index].latling.lat, lng: this.markers[index].latling.lng };
    this.windowReference[index].open(this.markerReference[index])
  }

  applyStyleToChild(elem: any): void {
    elem.item.classList.remove('e-active');
    elem.item.querySelector('.e-list-item').classList.add('e-active');
  }

  onProvidersListSelect(): void {
    const selectedItems = this.providersListView.getSelectedItems() as any;
    this.providerSelected.emit(selectedItems as Provider);
    this.applyStyleToChild(selectedItems);
    this.recenterMap(selectedItems.data.index);
  }

  onProviderClick(event: Event): void {
    const clickedElement = event.target as HTMLElement;
    const listItem = clickedElement.closest('li');
    // this.providersListView.selectItem()
    clickedElement.classList.add('e-active');

    if (listItem) {
      const childElement = listItem.querySelector('.e-list-wrapper');

      if (childElement) {
        childElement.classList.add('e-active')
      }

      this.providersListView.selectItem(listItem);
    }
  }

  getProviderName(providerId: number): string {
    let res = this.providerTypes.find(x => x.id === providerId)?.description ?? 'Type unkown';

    return res;
  }
}