<div id="modalities">
  
  @if (schedulerSignals.loading.ModalityTypes() || schedulerSignals.loading.Modalities()) {

    <loading-overlay [loading]="schedulerSignals.loading.ModalityTypes"></loading-overlay>
  
  } @else {
  
    <div *ngFor="let modalityType of modalityTypes(); index as i;">
      <div class="category {{modalityType.Description}}" [style.backgroundColor]="modalityColors[modalityType.Id ?? 0]">
        <ejs-checkbox #modalityCheckbox
          label="{{modalityType.Description}}" 
          value="{{modalityType.Id}}"
          [checked]="true"
          (change)="onModalityTypeChange($event, modalityType)">
        </ejs-checkbox>
      </div>
    </div>
  }
    
</div>