import { ResourcesModel, ScheduleComponent } from "@syncfusion/ej2-angular-schedule";
import { ListSchedulerLocation, ListModalityType, Appointment, CaseFile, Provider, ProcedureCode, ListAppointmentType, ListAppointmentStatus, ListModality } from "../data-contracts";
import { ChangeEventArgs } from "@syncfusion/ej2-buttons";
import { signal } from "@angular/core";
import { WritableSignal } from "@angular/core";

export interface UpdatedResourceEvent { 
    type: 'Locations' | 'Modalities';
    event: ChangeEventArgs;
    selection: ListModalityType | ListSchedulerLocation
}

export interface CalendarStates { 
    Calendar: CalendarSignals;
    Loading: CalendarLoadingSignals;
    Data: CalendarDataStates;
}

export interface CalendarSignals { 
    Component: WritableSignal<ScheduleComponent | undefined>;
    State: WritableSignal<string | undefined>;
    Resources: WritableSignal<ResourcesModel[] | undefined>;
}

export interface CalendarLoadingSignals {
    Locations: WritableSignal<boolean | undefined>;
    ModalityTypes: WritableSignal<boolean | undefined>;
    Modalities: WritableSignal<boolean | undefined>;
    Appointments: WritableSignal<boolean | undefined>;
    FormCaseFiles: WritableSignal<boolean | undefined>;
    FormProviders: WritableSignal<boolean | undefined>;
    FormProcedureCodes: WritableSignal<boolean | undefined>;
    FormAppointmentTypes: WritableSignal<boolean | undefined>;
    FormAppointmentStatuses: WritableSignal<boolean | undefined>;
}

export interface CalendarDataStates { 
    Locations: WritableSignal<ListSchedulerLocation[] | undefined>;
    ModalityTypes: WritableSignal<ListModalityType[] | undefined>;
    Modalities: WritableSignal<ListModality[] | undefined>;
    Appointments: WritableSignal<Appointment[] | undefined>;
    FormCaseFiles: WritableSignal<CaseFile[] | undefined>;
    FormProviders: WritableSignal<Provider[] | undefined>;
    FormProcedureCodes: WritableSignal<ProcedureCode[] | undefined>;
    FormAppointmentTypes: WritableSignal<ListAppointmentType[] | undefined>;
    FormAppointmentStatuses: WritableSignal<ListAppointmentStatus[] | undefined>;
}

export type SetFetchedDataAvailableTypes = 
  | ListSchedulerLocation
  | ListModalityType
  | Appointment
  | CaseFile
  | Provider
  | ProcedureCode
  | ListAppointmentType
  | ListAppointmentStatus;

  export const defaultModalityColors: { [key: string]: string } = {
    'ECT': '#B7BE59',
    'US': '#4775D7',
    'CV': '#A47FC7',
    'CT': '#4D978F',
    'CR': '#DA9757',
    'MR': '#A35878',
    'Other': '#999999',
};