// Angular
import { 
  Component,
  Input,
  ViewChild,
  ViewEncapsulation, 
  WritableSignal, 
  signal,
  Output,
  EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'is-what';
import { CommonModule } from '@angular/common';

// 3rd Party
import { 
  ScheduleAllModule,
  DayService, 
  WeekService, 
  WorkWeekService, 
  MonthService, 
  AgendaService, 
  EventSettingsModel, 
  GroupModel, 
  ScheduleComponent, 
  ResizeService, 
  DragAndDropService,
  TimelineViewsService,
  PopupOpenEventArgs,
  MonthAgendaService,
  TimelineMonthService,
  Timezone,
  RenderCellEventArgs,
  EventRenderedArgs,
  ExcelExportService,
  ICalendarExportService,
  PrintService,
  RecurrenceEditorAllModule } from '@syncfusion/ej2-angular-schedule';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';
import { Internationalization } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { Switch } from '@syncfusion/ej2-buttons';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { FontAwesomeModule, IconDefinition } from '@fortawesome/angular-fontawesome';
import { faCircleUser, faNotesMedical, faBriefcase, faXRay } from '@fortawesome/free-solid-svg-icons';

// Internal
import { Appointment } from '@models/data-contracts';
import { KeyValuePair } from '@services/globals/globals.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { SignalRService } from '@services/api/signal-r.service';
import { SchedulerService } from '@services/scheduler/scheduler.service';
import { CreateSyncfusionComponentsService } from '@services/create-syncfusion-components/create-syncfusion-components.service';
import { ProviderMapComponent } from '@features/provider-map/provider-map.component';
import { LoadingModule } from '@modules/loading.module';
import { LoadingComponent } from '@ui/loading/loading.component';
import { SchedulerSignalsService } from '@services/scheduler/scheduler-signals.service';

@Component({
  selector: 'scheduler-calendar',
  standalone: true,
  imports: [
    CommonModule,
    ScheduleAllModule,
    AccordionModule,
    CheckBoxModule,
    DialogModule,
    ProviderMapComponent,
    LoadingModule,
    RecurrenceEditorAllModule,
    FontAwesomeModule
  ],
  providers: [
    DayService, 
    WeekService, 
    WorkWeekService, 
    MonthService, 
    AgendaService, 
    ResizeService, 
    DragAndDropService, 
    TimelineMonthService,
    TimelineViewsService,
    MonthAgendaService,
    ExcelExportService,
    ICalendarExportService,
    PrintService
  ],
  templateUrl: './scheduler-calendar.component.html',
  styleUrl: './scheduler-calendar.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SchedulerCalendarComponent {
  @Input() componentContextId: string;
  @Input() inputAppointments: Appointment[];
  @Output() appointmentsData = new EventEmitter<any>();
  @Output() newAppointment = new EventEmitter<any>();
  @Output() changedAppointment = new EventEmitter<any>();

  constructor (
    private signalR: SignalRService,
    private toast: ToastMessageService,
    private scheduler: SchedulerService,
    private createSyncfusionComponent: CreateSyncfusionComponentsService,
    public schedulerSignals: SchedulerSignalsService

  ) {}

  // Calendar Setup
  @ViewChild('calendar') calendar: ScheduleComponent;
  persistence: Boolean = true;
  appointments: EventSettingsModel;
  todaysDate: Date = new Date();
  appointmentGroup: GroupModel = { resources: ['Locations', 'Modalities'], byGroupID: true, allowGroupEdit: true, enableCompactView: false };
  allowOverlap: boolean = true;
  scheduleHeight: string = 'auto';
  localTimezone: string = new Timezone().getLocalTimezoneName();
  selectedDate: Date = new Date();
  faCircleUser: IconDefinition = faCircleUser;
  faNotesMedical: IconDefinition = faNotesMedical;
  faBriefcase: IconDefinition = faBriefcase;
  faXRay: IconDefinition = faXRay;

  // Appointment Editing
  @ViewChild('providerMapDialog') providerMapDialog: DialogComponent;
  intl: Internationalization = new Internationalization();
  caseFilesData: WritableSignal<any> = signal(undefined);
  appointmentModality: DropDownList | undefined;
  appointmentPatient: DropDownList | undefined;
  appointmentProcedureCode: DropDownList | undefined;
  appointmentCaseFile: DropDownList | undefined;
  appointmentStatus: DropDownList | undefined;
  appointmentType: DropDownList | undefined;
  appointmentIsBlockSwitch: Switch | undefined;
  appointmentCompleteSwitch: Switch | undefined;
  appointmentProvider: DropDownList | undefined;
  providerMapDialogVisibility: Boolean = false;
  loading: LoadingComponent = new LoadingComponent;
  providerMapDialogButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.submitProviderSelection.bind(this), buttonModel: { content: 'Select This Provider', isPrimary: true, cssClass: 'e-outline' } },
  ];

  ngOnInit() {
    // this.signalR.startAppointmentHubConnection();
    // this.signalR.addReceiveJSONMessageListener((message) => {
    //   this.scheduler.receiveLockedDatesSignal(message, this.calendar);
    // });
    if (!this.inputAppointments) {
      this.schedulerSignals.fetchAppointments().then((appointments) => {
        const formattedAppointments = appointments?.map((appointment: Appointment) => this.scheduler.mapEventForSyncfusion(appointment, new Timezone()));
        this.appointments = { dataSource: formattedAppointments };
      });
    } else {
      const formattedAppointments = this.inputAppointments?.map((appointment: Appointment) => this.scheduler.mapEventForSyncfusion(appointment, new Timezone()));
      this.appointments = { dataSource: formattedAppointments };
    }
  }

  // Set shared signals after data bound to calendar
  onDataBound(args: any) {
    const savedState = localStorage.getItem(`schedule${this.calendar.element.id}`);
    this.schedulerSignals.calendar.Component.set(this.calendar);
    this.schedulerSignals.calendar.State.set(savedState ?? '');
    this.schedulerSignals.calendar.Resources.set(this.calendar.getResourceCollections());
  }

  onCreated(args: any) {
    this.setSchedulerDisplay();
  }

  // Observe and adjust height of scheduler
  setSchedulerDisplay() {
    const schedulerElement = document.querySelector('ejs-schedule') as HTMLElement;

    if (schedulerElement) {
      this.checkAndAdjustHeight(schedulerElement);
      
      // MutationObserver to detect changes in the schedulerElement
      const observer = new MutationObserver(() => {
        this.checkAndAdjustHeight(schedulerElement); // Re-check and adjust height on DOM changes
      });

      observer.observe(schedulerElement, { childList: true, subtree: true }); // Observe child elements and subtree
    }
  }

  // Add height of table contents and adjust
  private checkAndAdjustHeight(schedulerElement: HTMLElement) {
    const schedulerHeight = schedulerElement.clientHeight;
    const mainContentHeight = document.querySelector('main')?.clientHeight;

    if ((mainContentHeight && schedulerHeight) && (schedulerHeight > mainContentHeight)) {
      schedulerElement.style.height = `${Math.min(schedulerHeight, mainContentHeight)}px`;

      const toolbar = schedulerElement.querySelector('.e-schedule-toolbar-container') as HTMLElement;
      const table = schedulerElement.querySelector('.e-table-container') as HTMLElement;
      const leftIndent = schedulerElement.querySelector('.e-left-indent') as HTMLElement;

      if (toolbar && table) {
        const headerRow = table.querySelector('tr') as HTMLElement;
        const appointmentsRow = headerRow.nextElementSibling as HTMLElement;

        if (headerRow && appointmentsRow) {
          const allowedHeight = mainContentHeight - (toolbar.clientHeight + headerRow.clientHeight);
          const timeCellsWrap = appointmentsRow.querySelector('.e-time-cells-wrap') as HTMLElement;
          
          if (allowedHeight && timeCellsWrap) {
            appointmentsRow.style.height = `${allowedHeight}px`;
            appointmentsRow.style.overflowY = 'auto';
            appointmentsRow.style.display = 'block';
            appointmentsRow.style.width = `${headerRow.clientWidth}px`;
            timeCellsWrap.style.width = `${leftIndent.clientWidth}px`;
          }
        }
      }
    }
  }

  // Occurs when appointments are added to the calendar
  onRenderCell = async (args: RenderCellEventArgs) => {
    // Alternate opacity values for easier scanability between resources
    let groupNumber = args.element.getAttribute('data-group-index') as string;
    let classString = Number(groupNumber) % 2 === 0 ? 'even' : 'odd';
    args.element.classList.add(classString); 
  }

  // Occurs when users attempt edits and on load
  onActionBegin(args: any) {
    let startTime: Date;
    let endTime: Date;

    if ((args.requestType === 'eventCreate' || args.requestType === 'eventChange') && (<Object[]>args.data).length > 0 || !isNullOrUndefined((args as any).data)) {
      let eventData: any = args.data as any;

      // Check if data is array of events
      if (Array.isArray(eventData)) {

        // Apply logic for each event in the array
        eventData.forEach((event: any) => {
          startTime = event.occurrence ? event.occurrence.StartTime : event.StartTime;
          endTime = event.occurrence ? event.occurrence.EndTime : event.endTime;

          // Ensure each event does not occur in the past
          if (args.requestType === 'eventCreate' && (startTime < this.todaysDate || endTime < this.todaysDate)) {
            args.cancel = true;
            this.toast.showError('New events cannot occur in the past.');

          } else {

            // Check slot availability
            if (this.allowOverlap === false) {
              args.cancel = !this.calendar?.isSlotAvailable(startTime, endTime);

              // If slot not available, display error to user
              if (args.cancel === true) {
                this.toast.showError('Time slot not available.');
              }
            }
          }
        });

      } else {
        startTime = eventData.occurrence ? eventData.occurrence.StartTime : eventData.StartTime;
        endTime = eventData.occurrence ? eventData.occurrence.EndTime : eventData.endTime;

        // Ensure each event does not occur in the past
        if (args.requestType === 'eventCreate' && (startTime < this.todaysDate || endTime < this.todaysDate)) {
          args.cancel = true;
          this.toast.showError('New events cannot occur in the past.');

        } else {

          // Check slot availability
          if (this.allowOverlap === false) {
            args.cancel = !this.calendar?.isSlotAvailable(startTime, endTime);

            // If slot unavailable, display error to user
            if (args.cancel === true) {
              this.toast.showError('Time slot not available.');
            }

          } else {

          // If editing single event in series, remove recurrence info from edited event to prevent unwanted duplicates
            if ((args.data as any).occurrence) {
              (args.data as any).occurrence.RecurrenceRule = null;
              (args.data as any).occurrence.RecurrenceException = null;
            }
          }
        }
      }
    }
  }

  // Occurs when users save edits and after load resolves
  onActionComplete(args: any) {

    // Apply batch saves
    if (args.requestType === 'eventCreated' || args.requestType === 'eventChanged' || args.requestType === 'eventRemoved') {
      this.scheduler.batchSaveSchedule(args);
      this.scheduler.removeUserEditingElements();
      this.calendar?.refreshEvents();
    }
  }

  async onPopupOpen(args: PopupOpenEventArgs) {
    console.log('onPopupOpen', args);
    const dialogElement: HTMLElement = args.element as HTMLElement;
    const dialogHeader: Element = dialogElement.querySelector('.e-dlg-header-content') as Element;
    const appointment = args.data as Appointment;
    let editingAppointment: boolean = !!(args.data && (args.data as any).Id);

    // Validate data
    if (!args.data) return;

    if (args.type === 'QuickInfo') {
      this.handleQuickInfoPopup(args);
    } 

    // Check if user starts editing an appointment
    if (args.type === 'Editor') {
      const editorForm: Element = args.element.querySelector('.e-schedule-form') as Element;
      const appointmentTitle: Element = editorForm.querySelector('.e-title-location-row') as Element;
      const resourcesSection: Element = editorForm.querySelector('.e-resources-row') as Element;

      if (!editingAppointment) Object.assign((dialogHeader as HTMLElement).style, this.scheduler.removeHeaderStyles());
      this.updateDialogHeader(dialogHeader, appointment, editingAppointment);
      this.prepareEditingAppointment(appointmentTitle, resourcesSection);
      this.addSwitchForBlockingTime(dialogElement, appointment);
      this.addCustomFields(resourcesSection, appointment);
    }
  }

  private handleQuickInfoPopup(args: PopupOpenEventArgs) {
    if (args.target?.tagName === 'TD') {
      this.scheduler.removeUserEditingElements();
      this.signalR.broadcastHTMLElement(args.target, 'data-date', 'add');
    }

    const location = args.element.querySelector('.e-popup-content')?.querySelector('.e-location')?.querySelector('.e-location-details') as HTMLElement;
    if (location && args.data) {
      location.innerHTML = args.data['Location'].Description;
      console.log('location', location.innerHTML, location);  
    }
  }

  private updateDialogHeader(dialogHeader: Element, appointment: Appointment, editingAppointment: boolean) {
    if (dialogHeader && appointment['ModalityId'] && editingAppointment) {
      const dialogHeaderStyles = this.scheduler.getEventHeaderStyles(appointment as KeyValuePair, this.calendar);
      Object.assign((dialogHeader as HTMLElement).style, dialogHeaderStyles);
    }
  }

  private prepareEditingAppointment(appointmentTitle: Element, resourcesSection: Element) {
      this.removeDefaultElements(appointmentTitle);
      this.moveDropdowns(appointmentTitle, resourcesSection);      
  }

  private removeDefaultElements(appointmentTitle: Element) {
    const locationContainer = appointmentTitle.querySelector('.e-location-container');
    const subjectContainer = appointmentTitle.querySelector('.e-subject-container');
    if (locationContainer) locationContainer.remove();
    if (subjectContainer) subjectContainer.remove();
  }

  private moveDropdowns(appointmentTitle: Element, resourcesSection: Element) {
    const locationDropdown = resourcesSection.querySelector('.e-LocationId-container') as Element;
    const modalityDropdown = resourcesSection.querySelector('.e-ModalityId-container') as Element;

    if (locationDropdown) appointmentTitle.appendChild(locationDropdown);
    if (modalityDropdown) appointmentTitle.appendChild(modalityDropdown);
  }

  private async addCustomFields(resourcesSection: Element, appointment: Appointment) {
    const noCustomFields: boolean = resourcesSection.querySelector('.custom-field-row') === null;

    if (noCustomFields) {
      resourcesSection.classList.add('row');
      this.styleResourceSectionChildren(resourcesSection);
      this.addDropDownsForAppointment(resourcesSection, appointment);
    }
}

  private styleResourceSectionChildren(resourcesSection: Element) {
    Array.from(resourcesSection.children).forEach(child => {
      child.classList.add('col-12', 'col-md-6', 'col-lg-4');
      child.classList.remove('e-resources');

      const customFields = child.querySelectorAll('.custom-field-row');
      customFields.forEach(field => {
          field.classList.add('col-12', 'col-md-6');
      });
    });
  }

  private async addSwitchForBlockingTime(dialogElement: Element, appointment: Appointment) {
    if (this.appointmentIsBlockSwitch === undefined) {
      const timeRow = dialogElement.querySelector('.e-all-day-time-zone-row') as Element;
      const isBlockSwitchOptions = { checked: appointment['Block'] as boolean, onLabel: 'Locked', offLabel: 'Time Slot Open', name: 'IsBlock' };
      this.appointmentIsBlockSwitch = this.createSyncfusionComponent.createSwitch(isBlockSwitchOptions);
      this.scheduler.addComponentToEditorWindow(timeRow, this.appointmentIsBlockSwitch, 'isBlock', 'custom-field-container');
    }
  }

  private async addDropDownsForAppointment(resourcesSection: Element, appointment: Appointment) {
    await this.addDropDownForCaseFile(resourcesSection, appointment);
    await this.addDropDownForAppointmentType(resourcesSection, appointment);
    await this.addDropDownForAppointmentStatus(resourcesSection, appointment);
    await this.addDropDownForProcedureCode(resourcesSection, appointment);
    await this.addDropDownForProvider(resourcesSection, appointment);
  }

  private async addDropDownForCaseFile(resourcesSection: Element, appointment: Appointment) {
    this.schedulerSignals.fetchCaseFiles().then((result) => {
      if (this.appointmentCaseFile === undefined) {
        const caseFiles = result?.map((result: any) => {
          return {
            Id: result.Id,
            Text: `CaseFile #: ${result.FileNumber} Type: ${result.CaseType}`
          }
        });
        const caseFilesDrpDownOptions = { dataSource: caseFiles, fields: { text: 'Text', value: 'Id' }, placeholder: 'Case File', value: appointment['CaseFileId'] };
        this.appointmentCaseFile = this.createSyncfusionComponent.createDropDownList(caseFilesDrpDownOptions);
        this.scheduler.addComponentToEditorWindow(resourcesSection, this.appointmentCaseFile, 'CaseFileId', 'custom-field-row col-12 col-md-6 col-lg-4');
      }
    }); 
  }

  private async addDropDownForAppointmentType(resourcesSection: Element, appointment: Appointment) {
    this.schedulerSignals.fetchAppointmentTypes().then((result) => {
      if (this.appointmentType === undefined) {
        const typeDrpDwnOptions = { dataSource: result as any[], fields: { text: 'Description', value: 'Id' }, placeholder: 'Appointment Type', value: appointment['AppointmentTypeId'] };
        this.appointmentType = this.createSyncfusionComponent.createDropDownList(typeDrpDwnOptions);
        this.scheduler.addComponentToEditorWindow(resourcesSection, this.appointmentType, 'AppointmentTypeId', 'custom-field-row col-12 col-md-6 col-lg-4');
      }
    });
  }

  private async addDropDownForAppointmentStatus(resourcesSection: Element, appointment: Appointment) {
    this.schedulerSignals.fetchAppointmentStatuses().then((result) => {
      if (this.appointmentStatus === undefined) {
        const statusDrpDwnOptions = { dataSource: result as any[], fields: { text: 'Description', value: 'Id' }, placeholder: 'Status', value: appointment['AppointmentStatusId'] };
        this.appointmentStatus = this.createSyncfusionComponent.createDropDownList(statusDrpDwnOptions);
        this.scheduler.addComponentToEditorWindow(resourcesSection, this.appointmentStatus, 'AppointmentStatusId', 'custom-field-row col-12 col-md-6 col-lg-4');
      }
    });
  }

  private async addDropDownForProcedureCode(resourcesSection: Element, appointment: Appointment) {
    this.schedulerSignals.fetchProcedureCodesMatchingModalityType(appointment.Modality?.ModalityTypeId ?? 0).then((result) => {
      console.log('result', result);
      if (this.appointmentProcedureCode === undefined) {
        const procedureCodesDrpDwnOptions = { dataSource: result as any[], fields: { text: 'Description', value: 'Id' }, placeholder: 'Procedure', value: appointment['ProcedureCodeId']};
        this.appointmentProcedureCode = this.createSyncfusionComponent.createDropDownList(procedureCodesDrpDwnOptions);
        this.scheduler.addComponentToEditorWindow(resourcesSection, this.appointmentProcedureCode, 'ProcedureCodeId', 'custom-field-row col-12 col-md-6 col-lg-4');
      }
    });
  } 

  private async addDropDownForProvider(resourcesSection: Element, appointment: Appointment) {
    this.schedulerSignals.fetchProviders().then((result) => {
      if (this.appointmentProvider === undefined) {
        const providersDrpDwnOptions = { dataSource: result as any[], fields: { text: 'Name', value: 'Id' }, placeholder: 'Provider', value: appointment['ProviderId']};
        this.appointmentProvider = this.createSyncfusionComponent.createDropDownList(providersDrpDwnOptions);
        this.scheduler.addComponentToEditorWindow(resourcesSection, this.appointmentProvider, 'ProviderId', 'custom-field-row col-12 col-md-6 col-lg-4');
      }
    });
  } 

  // Occurs whenever a popup modal is closed
  onPopupClose(args: any) {
    this.scheduler.removeUserEditingElements();

    // Ensure popup window is Editor before updating values
    if (args.type === 'Editor') {
      this.clearEditorWindowFields();
    }
  }

  // Add completed styles to completed appointments
  onEventRendered(args: EventRenderedArgs) {

    if (args.data['IsCompleted']) {
      args.element.classList.add('complete');
    } else {
      args.element.classList.add('incomplete');
    }
  }

  // Provider Map items below
  @ViewChild('providerMap') providerMap: ProviderMapComponent;
  markerWindowTxt: string = 'Select this Provider';
  provider: any;

  onProviderSelected(provider: any) {
    this.provider = provider.data;
  }

  closeDialog() {
    this.providerMapDialog.hide();
  }

  submitProviderSelection() {
    (this.appointmentProvider as DropDownList).value = this.provider.id;
    this.providerMapDialog.hide();
  }

  clearEditorWindowFields() {
    if (this.appointmentCaseFile) {
      this.appointmentCaseFile.value = null;
    }
    if (this.appointmentCaseFile) {
      this.appointmentCaseFile.value = null;
    }
    if (this.appointmentIsBlockSwitch) {
      this.appointmentIsBlockSwitch.value = '';
    }
    if (this.appointmentModality) {
      this.appointmentModality.value = null;
    }
    if (this.appointmentProcedureCode) {
      this.appointmentProcedureCode.value = null;
    }
    if (this.appointmentProcedureCode) {
      this.appointmentProcedureCode.value = null;
    }
  }
}
