<div id="provider-map">

    <div class="row">

        <div class="col-12 col-md-4">
            <div class="search">
                <form class="d-flex flex-colum justify-content-between" [formGroup]="searchFormGroup" (ngSubmit)="generateMarkersBasedOnFilter()">
                    <ejs-textbox id="enter_address" formControlName="client_address" floatLabelType="Auto" placeholder="Enter the client address" />
                    <ejs-numerictextbox id="enter_radius" formControlName="radius" floatLabelType="Auto" placeholder="Search Radius (mi)" />
                    <ejs-multiselect #providerTypesDrpdwn name="provider_type" formControlName="provider_type" floatLabelType="Auto" placeholder="Provider Type" 
                        [dataSource]="providerTypes" 
                        [fields]="{ text: 'description', value: 'id' }" />
                    <div class="form-submit d-flex justify-content-end">
                        <button id="search-provider-btn" ejs-button type="submit" isPrimary="true">Search</button>
                    </div>
                </form>
            </div>
        
            <spacer-element />
            
            <div class="list">
                <ejs-listview #providersListView id="providersListView" headerTitle="Results" cssClass="e-list-template" sortOrder="Ascending"  headerTitle='Results'  
                    [showHeader]="true"
                    [dataSource]="providersListData"
                    (select)="onProvidersListSelect()">
                    <ng-template #template let-data>
                        <div [id]="data.index" class="e-list-item e-card" (click)="onProviderClick($event)">
                            <div [id]="data.index" class="e-list-wrapper e-list-multi-line">
                                <h2>{{data.name}} ({{data.index + 1}})</h2>
                                <p><b>Distance: </b>{{data.distance}} Miles</p>
                                <p><b>Provider Type: </b>{{getProviderName(data.providerType)}}</p>
                                <p><b>Provider Priority: </b>{{data.color[0].toUpperCase() + data.color.substring(1)}}</p>
                            </div>
                        </div>
                    </ng-template>
                </ejs-listview>
            </div>
        </div>

        <div class="col-12 col-md-8">
            <div class="map">
                <google-map height="100%" width="100%" [center]="center" [zoom]="zoom" [options]="{}" mapId="DEMO_MAP_ID">
                    <map-marker-clusterer [imagePath]="clustererImagePath" [zoomOnClick]="false" (clusterClick)="clusterClick($event)">
        
                        @for (mark of markers; track mark; let idx = $index) {
        
                            <map-info-window #window="mapInfoWindow" style="color:black">
                                <div class="provider-heading">
                                    <h2>{{mark.name}} ({{idx+1}})</h2>
                                </div>
                                <div class="provider-info">
                                    <p><b>Notes: </b>{{mark.notes}}</p>
                                    <p><b>Distance: </b>{{mark.distance}} Miles</p>
                                    <p><b>Reinbursement Rate: </b>{{mark.reimbursement}}%</p>
                                    <p><b>Spanish Speaking: </b>{{mark.spanishSpeaking}}</p>
                                    <p><b>Phone: </b>
                                        @if (getNumber(mark.id) === 'No Number Available') { {{getNumber(mark.id)}} }
                                        @else { <a href="tel:">{{getNumber(mark.id)}}</a> }
                                    </p>
                                </div>
        
                                <div class="provider-actions">
                                    @if (isCluster) {
                                        <div class="d-flex flex-row justify-content-center">
                                            <button ejs-button (click)="getPreviousInfoWindow(window)" iconCss="e-icons e-chevron-left-double"></button>
                                            <button ejs-button *ngIf="hideWindowBtnFn !== true" (click)="windowBtnFn($event)">{{windowBtnText}}</button>
                                            <button ejs-button (click)="getNextInfoWindow(window)" iconCss="e-icons e-chevron-right-double"> </button>
                                        </div>
                                        <p>{{clusterMarkerIndex+1}}/{{clusterMarkerIds.length}}</p>
                                    } @else {
                                        <div class="d-flex flex-row justify-content-center">
                                            <button ejs-button (click)="windowBtnFn($event)">{{windowBtnText}}</button>
                                        </div>
                                    }
                                </div>
                            </map-info-window>
        
                            <map-marker #marker="mapMarker" idx=idx
                                [position]="mark.latling"
                                [icon]="'/assets/maps/images/marker/' + mark.color + '.svg'"
                                [label]="getLabel((idx+1).toString(), mark.color)"
                                (mapClick)="OnMarkerclick(window, marker)"
                                (markerInitialized)="addReferenceToWindowMarkerPair(window, marker)">
                            </map-marker>
                        }
                        
                    </map-marker-clusterer>
        
                    <map-info-window #window="mapInfoWindow">
                        <div class="provider-heading">
                            <h2>Home</h2>
                        </div>
                        <div class="provider-info">
                            <p>Address: {{patientAddress}}</p>
                        </div>
                    </map-info-window>
        
                    <map-marker #marker="mapMarker"
                        [position]="patientAddressLatLong"
                        [icon]="'/assets/maps/images/home-32.png'"
                        (mapClick)="OnMarkerclick(window, marker)">
                    </map-marker>
        
                    <map-marker #placeholdermarker="mapMarker" 
                        [position]="placeHolderPosition"
                        [icon]="'/assets/maps/images/blank-32.png'"
                        (markerInitialized)="setPlaceholderMarker(placeholdermarker)">
                    </map-marker>
                </google-map>
            </div>
        </div>
    </div>
</div>