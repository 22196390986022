// Angular
import { Injectable } from '@angular/core';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';
import { EmitType } from '@syncfusion/ej2-base';
import { FloatLabelType } from '@syncfusion/ej2-inputs';
import { DropDownList, DropDownListModel, FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { Switch, SwitchModel, Button, ButtonModel } from '@syncfusion/ej2-buttons';

@Injectable({
  providedIn: 'root'
})
export class CreateSyncfusionComponentsService {

  constructor() { }

  // Filters DropDownList results
  onDropdownFiltering: EmitType<FilteringEventArgs> = (filterEvent: FilteringEventArgs, data: any) => {
    let query = new Query();

    query = (filterEvent.text != "") ? query.where("Text", "contains", filterEvent.text, true) : query;
    filterEvent.updateData(data, query);
  };

  createDropDownList(options?: DropDownListModel) {

    return new DropDownList({
      dataSource: options?.dataSource ?? [],
      fields: options?.fields ?? { text: 'Text', value: 'Id' },
      value: options?.value ?? '',
      floatLabelType: options?.floatLabelType ?? 'Always', 
      placeholder: options?.placeholder ?? 'Select',
      allowFiltering: options?.allowFiltering ?? true,
      created: (event: any) => options?.created?.(event),
      filtering: (event: any) => this.onDropdownFiltering(event, options?.dataSource)
    })
  }

  createSwitch(options?: SwitchModel) {

    return new Switch({
      checked: options?.checked ?? false,
      onLabel: options?.onLabel ?? 'On',
      offLabel: options?.offLabel ?? 'Off',
      name: options?.name,
      enableRtl: options?.enableRtl ?? false,
      change: (args) => { return args; }
    });
  }
}
