<div id="locations">

    @if (schedulerSignals.loading.Locations()) {

        <loading-overlay [loading]="schedulerSignals.loading.Locations"></loading-overlay>
        
    } @else {

        <div *ngFor="let location of locations(); index as i;">
            <div class="category {{location.Description}}">
                <ejs-checkbox #locationCheckbox
                    label="{{location.Description}}" 
                    value="{{location.Id}}"
                    [checked]="true"
                    (change)="onLocationChange($event, location)">
                </ejs-checkbox>
            </div>
        </div>
    }

</div>