// Angular
import { Component, Output, EventEmitter, OnInit, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
// 3rd Party
import { CheckBoxModule, ChangeEventArgs } from '@syncfusion/ej2-angular-buttons';

// Internal
import { ListSchedulerLocation } from '@models/data-contracts';
import { UpdatedResourceEvent } from '@root/src/app/shared/models/components/scheduler.model';
import { LoadingModule } from '@modules/loading.module';
import { SchedulerSignalsService } from '@root/src/app/shared/services/scheduler/scheduler-signals.service';
import { SchedulerService } from '@root/src/app/shared/services/scheduler/scheduler.service';

@Component({
  selector: 'scheduler-locations',
  standalone: true,
  imports: [
    CommonModule,
    CheckBoxModule,
    LoadingModule
  ],
  templateUrl: './scheduler-locations.component.html',
  styleUrl: './scheduler-locations.component.scss'
})
export class SchedulerLocationsComponent implements OnInit {
  @Output() clickedLocation = new EventEmitter<UpdatedResourceEvent>();

  constructor(
    private scheduler: SchedulerService,
    public schedulerSignals: SchedulerSignalsService
  ) {}
  
  readonly locations = computed(() => this.schedulerSignals.data.Locations());

  ngOnInit(): void {
    this.schedulerSignals.fetchLocations();
  }

  // Emit UpdatedResourceEvent
  onLocationChange(args: ChangeEventArgs, location: ListSchedulerLocation) {
    const selected: UpdatedResourceEvent = { type: 'Locations', event: args, selection: location };
    this.clickedLocation.emit(selected);
    this.scheduler.updateResources(selected);
  }
}
